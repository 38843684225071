import React, { useEffect } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import { Helmet } from "react-helmet"
function AEOPage({ data }) {
  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  }, [])
  return (
    <Layout language="en">
      <SEO
        title={"AEO | Nuvolum"}
        description="Schedule a 5 minute phone call."
        robots="noindex"
      />
      {/* <Helmet>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async></script>
      </Helmet> */}
      <Section>
        <Container>
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/jock-cal/5-minute-phone-call"
            style={{ minWidth: "320px", height: "700px" }}></div>
        </Container>
      </Section>
    </Layout>
  )
}
export default AEOPage
